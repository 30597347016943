import axios from 'axios'
import {
	getToken
} from '@/utils/getToken'
import {
	Message
} from 'element-ui'
// 创建axios实例
const request = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API, 
	// baseURL: 'http://8.134.169.114:9005', 
	// baseURL: 'http://26.198.70.51:8081', //森林
	// baseURL: 'http://192.168.3.11:8081', //白帅
	// baseURL: 'http://47.102.134.15:8081', 
	baseURL: 'http://47.102.109.169:8081',
	// baseURL: 'http://www.yiqingkang.top:8081',
	
	withCredentials: false, // 跨域请求时是否需要访问凭证
	timeout: 3 * 1000, // 请求超时时间

})

// 请求拦截器
request.interceptors.request.use((config) => {
	// console.log(process.env.production)
	config.headers.token = sessionStorage.getItem('token')
	config.headers.id = sessionStorage.getItem('userID')
	return config;
}, (error) => {
	return Promise.reject(error)
})

request.interceptors.response.use((response) => {

	let {
		code,
		message
	} = response.data
	if (code != 0) {
		Message({
			message: message || 'error',
			type: 'warning'
		})
	}
	return Promise.resolve(response.data);
}, (error) => {
	return Promise.reject(error)
})

export default request; //记得暴露出去