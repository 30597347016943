import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入iconfont图标
import "./assets/font_icon/iconfont.css"
// 加载 Vant 核心组件库
import Vant from 'vant'
// 加载 Vant 全局样式
import 'vant/lib/index.css'
// 动态设置 REM 基准值
import 'amfe-flexible'
import {
	Lazyload
} from 'vant';
Vue.use(Lazyload);
// 注册使用 Vant 组件库
Vue.use(Vant)

// 引入公共的css
import '@/assets/css/common.css'


Vue.prototype.$name = '河南晨潇信息科技有限公司'
Vue.prototype.$addr = '河南自贸试验区郑州片区（郑东）普惠路78号绿地之窗景峰座1号楼20层201-3室'
Vue.prototype.$phone = '13653713125'
Vue.prototype.$email = '2718526689@qq.com'
Vue.use(ElementUI);

Vue.config.productionTip = false


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')